import React, { ReactElement, useMemo } from 'react';

import { Box, Typography, TextField, Checkbox, Chip } from '@material-ui/core';
import { FilterOption } from 'models/reporte_model';
import { Autocomplete } from '@material-ui/lab';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { RootState, useStateSelector } from 'store/reducers';

const checkedIcon = <CheckBox fontSize="small" />;
const icon = <CheckBoxOutlineBlank fontSize="small" />;

type Props = {
  name: string;
  label: string;
  value?: string;
  options: FilterOption[];
  onChange: (name: string, value: string | number) => void;
  disabled?: boolean;
  selectAllOption?: boolean;
  maxSelectedOptions?: number;
};

export const MultiSelectFilter = ({
  name,
  label,
  value,
  options,
  onChange,
  disabled,
  selectAllOption = true,
  maxSelectedOptions = 2,
}: Props): ReactElement => {
  const _values = typeof value !== 'undefined' ? value.split(',') : [];

  const { locale } = useStateSelector((state: RootState) => state.i18n);

  const TEXTO_MARCAR_TODOS =
    locale === 'es' ? 'Marcar todos' : 'Alles Markieren';

  const fullOptions = useMemo(() => {
    if (options.length === 1) {
      return options;
    }
    return selectAllOption
      ? [{ label: TEXTO_MARCAR_TODOS, value: '__all__' }, ...options]
      : options;
  }, [options, selectAllOption, TEXTO_MARCAR_TODOS]);

  const selectedValues = useMemo(() => {
    return options.filter((v) => _values.includes(String(v.value)));
  }, [options, _values]);

  const allSelected = useMemo(() => {
    return options.length > 0 && selectedValues.length === options.length;
  }, [options, selectedValues]);

  const handleChange = (
    _: React.ChangeEvent<unknown>,
    selected: FilterOption[],
  ) => {
    const allOptionValue = '__all__';
    const isSelectAllClicked = selected.some((x) => x.value === allOptionValue);

    // Todos los valores reales (sin "__all__")
    const selectedRealValues = selected
      .filter((x) => x.value !== allOptionValue)
      .map((x) => x.value);

    const isCurrentlyAllSelected = selectedValues.length === options.length;

    if (isSelectAllClicked) {
      // Si se dio click en "Seleccionar todos"
      if (isCurrentlyAllSelected) {
        if (selectedRealValues.length === options.length) {
          onChange(name, '');
        } else {
          onChange(name, selectedRealValues.join(','));
        }
      } else {
        // Marcar todos
        const allValues = options.map((opt) => opt.value).join(',');
        onChange(name, allValues);
      }
    } else {
      if (isCurrentlyAllSelected) {
        const newValues = selectedRealValues.join(',');
        onChange(name, newValues);
      } else {
        onChange(name, selectedRealValues.join(','));
      }
    }
  };

  return (
    <Box>
      <Autocomplete
        style={{
          width: '100%',
          height: '100%',
        }}
        multiple
        disableCloseOnSelect
        options={fullOptions}
        getOptionLabel={(option: FilterOption) =>
          option.caption ? `${option.label} - ${option.caption}` : option.label
        }
        value={
          allSelected && selectAllOption
            ? [
                { label: TEXTO_MARCAR_TODOS, value: '__all__' },
                ...selectedValues,
              ]
            : selectedValues
        }
        onChange={handleChange}
        disabled={disabled}
        noOptionsText="No hay opciones disponibles"
        renderOption={(option, { selected }) => {
          const isSelectAll = option.value === '__all__';
          const isChecked = isSelectAll ? allSelected : selected;

          return (
            <>
              <Checkbox
                key={option.value}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 2 }}
                checked={isChecked}
                indeterminate={
                  isSelectAll && selectedValues.length > 0 && !allSelected
                }
              />
              {option.label}

              {option.caption && (
                <Typography variant="caption">{option.caption}</Typography>
              )}
            </>
          );
        }}
        renderTags={(tagValue, getTagProps) => {
          const validOptions = tagValue.filter((option) =>
            options.some((opt) => opt.value === option.value),
          );

          if (validOptions.length > maxSelectedOptions) {
            return (
              <Chip
                label={`${validOptions.length} seleccionados`}
                {...getTagProps({ index: 0 })}
              />
            );
          }

          return validOptions.map((option, index) => (
            <Chip
              key={index}
              label={option.label}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={label}
            variant="outlined"
            style={{ width: '100%' }}
          />
        )}
      />
    </Box>
  );
};
